import Vue from "vue";
import VueI18n from "vue-i18n";
import { BootstrapVue } from "bootstrap-vue";
import Vuelidate from "vuelidate";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import "./assets/sass/app.scss";
import PlaceholderDirective from "@/directives/PlaceholderDirective";
import Toasted from "vue-toasted";
import { IMainTab } from "@/interfaces/IMainTab";
import Tinymce from "@/components/Editor/Tinymce.vue";
//@ts-ignore
import VueSignaturePad from "vue-signature-pad";
import moment from "moment";
import "@/icons/Thin";
import "@/icons/Solid";
import "@/icons/Custom";
import "@/icons/Light";
import DaysOfWeek from "@/constants/DaysOfWeek";
import FscMultiselect from "@/components/FscMultiselect.vue";
import VueKeepScrollPositionPlugin from "@/plugins/KeepScrollPositionPlugin";
// @ts-ignore
import { format } from "@/utils/money/utils";
import AbilityPlugin from "@/plugins/AbilityPlugin";
import CommunicationBadge from "@/components/Badge/CommunicationBadge.vue";
import RegistrationBadge from "@/components/Badge/RegistrationBadge.vue";
import EmailBadge from "@/components/Badge/EmailBadge.vue";
//@ts-ignore
import VueEllipseProgress from "vue-ellipse-progress";
//@ts-ignore
import KProgress from "k-progress";

Vue.config.productionTip = false;

Vue.use(VueI18n);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(Toasted, {
  duration: 6000,
});
Vue.use(VueKeepScrollPositionPlugin, { router });

Vue.use(AbilityPlugin, {
  router,
});

// Keep alive helper
Vue.prototype.$destroyKey = function (key: string) {
  // this.$vnode.parent.componentInstance.cache;
  // const keys = this.$vnode.parent.componentInstance.keys;
  // const outKey = keys.shift();
  const cache = this.$vnode.context.$vnode.parent.componentInstance.cache;
  cache[key].componentInstance.$destroy();
  cache[key] = null;
};

// Global mixin for tabs
Vue.use((Vue) => {
  Vue.mixin({
    methods: {
      async reload() {
        this.$parent.$emit("reload-page");
        this.$emit("reload-page");
      },
    },

    mounted: function () {
      // @ts-ignore
      if (!this.$attrs.routerKey) return;

      // @ts-ignore
      this.$root.$on("close-tab", (tab: IMainTab) => {
        if (
          // @ts-ignore
          !this._isDestroyed &&
          // @ts-ignore
          this.name + this.$attrs.routerKey == tab.name + tab.id
        ) {
          // @ts-ignore
          this.$destroyKey(tab.id);
        }
      });

      //@ts-ignore
      this.$root.$on("refresh-page", (tab: IMainTab) => {
        if (
          // @ts-ignore
          !this._isDestroyed &&
          // @ts-ignore
          this.name + this.$attrs.routerKey == tab.name + tab.id
        ) {
          // @ts-ignore
          this.reload();
        }
      });
    },
  });
});

// eslint-disable-next-line @typescript-eslint/no-var-requires
Vue.use(require("vue-moment"));
Vue.use(VueSignaturePad);
Vue.use(VueEllipseProgress);
Vue.use(KProgress);

Vue.directive("fscplacehoder", PlaceholderDirective);

Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("fsc-multiselect", FscMultiselect);
Vue.component("CommunicationBadge", CommunicationBadge);
Vue.component("RegistrationBadge", RegistrationBadge);
Vue.component("EmailBadge", EmailBadge);
Vue.component("tinymce", Tinymce);
Vue.component("k-progress", KProgress);

Vue.filter("formatDate", function (date: any) {
  if (!date) {
    return "";
  } else {
    return moment(date).format("DD.MM.YYYY");
  }
});

Vue.filter("formatDateAndHour", function (date: any) {
  if (!date) {
    return "";
  } else {
    return moment(date).format("DD.MM.YYYY HH:mm");
  }
});

Vue.filter("dayOfWeek", function (date: any) {
  if (!date) {
    return "";
  } else {
    return DaysOfWeek[moment(date).weekday() - 1];
  }
});

Vue.filter("formatTime", function (time: any) {
  if (!time) {
    return "";
  } else {
    return moment(time, "HH:mm:ss").format("HH:mm");
  }
});

Vue.filter("formatAmountInEur", function (amountInEur: any) {
  const moneyOption = {
    decimal: ",",
    thousands: "",
    prefix: "",
    suffix: "",
    precision: 2,
    masked: false,
  };
  return amountInEur !== "" ? `${format(amountInEur, moneyOption)} €` : "";
});

Vue.filter("formatHour", function (date: any) {
  if (!date) {
    return "";
  } else {
    return moment(date).format("HH:mm");
  }
});

Vue.directive("init", {
  bind: function (el, binding, vnode) {
    if (!binding.value?.bindTo) {
      throw new Error("bindTo is required");
    }

    if (!binding.value?.value) {
      throw new Error("value is required");
    }

    // @ts-ignore
    vnode.context[binding.value.bindTo] = binding.value.value;
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
