import { AxiosResponse } from "axios";
import axios from "@/utils/axios";
import { IS_LOADING, SET_ERROR, SET_DATA_ITEM, SET_SUCCESS, SET_MIME_TYPE, SET_DATA_LIST } from "./types";
import { ActionTree } from "vuex";
import { State } from "./state";
import { create, findOne, update, filter, findAll } from "@/store/Crud/functions/actions";
// @ts-ignore

const URL = "/cost-bearer-documents";

export const findCostBearerDocument = async (store: any, id: number): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios({
    method: "get",
    url: `${URL}/${id}`,
    responseType: "arraybuffer",
  })
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
      const mimeType = response.headers["content-type"].toLowerCase();
      store.commit(SET_MIME_TYPE, mimeType);
      const blob = response.data;
      store.commit(SET_DATA_ITEM, blob);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export const findCostBearerDocumentWithLetterHead = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios({
    method: "get",
    url: `${URL}/${options.id}?removeLetterhead=${options.removeLetterhead}`,
    responseType: "arraybuffer",
  })
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
      const mimeType = response.headers["content-type"].toLowerCase();
      store.commit(SET_MIME_TYPE, mimeType);
      const blob = response.data;
      store.commit(SET_DATA_ITEM, blob);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export const generateCostBearerInvoicePreview = async (store: any, options: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios({
    method: "post",
    url: `/cost-bearer-service-invoice/${options.id}/preview`,
    responseType: "arraybuffer",
    data: options.data,
  })
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
      const mimeType = response.headers["content-type"].toLowerCase();
      store.commit(SET_MIME_TYPE, mimeType);
      const blob = response.data;
      store.commit(SET_DATA_ITEM, blob);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export const costBearerPaymentInvoicePreview = async (store: any, data: any): Promise<void> => {
  store.commit(IS_LOADING, true);
  store.commit(SET_SUCCESS, false);
  return await axios({
    method: "post",
    url: `/payment-documents/create-cost-bearer-invoice-preview`,
    responseType: "arraybuffer",
    data: data,
  })
    .then((response: AxiosResponse) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_SUCCESS, true);
      const mimeType = response.headers["content-type"].toLowerCase();
      store.commit(SET_MIME_TYPE, mimeType);
      const blob = response.data;
      store.commit(SET_DATA_ITEM, blob);
    })
    .catch(({ response }) => {
      store.commit(IS_LOADING, false);
      store.commit(SET_ERROR, response.data);
      store.commit(SET_SUCCESS, false);
    });
};

export default <ActionTree<State, any>>{
  create,
  filter,
  findOne,
  findAll,
  update,
  findCostBearerDocument,
  findCostBearerDocumentWithLetterHead,
  generateCostBearerInvoicePreview,
  costBearerPaymentInvoicePreview,
};
